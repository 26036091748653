import React, { useEffect } from "react";
import "./MainOrder.scss";
import ModalDelete from "../../../components/ModalDelete/ModalDelete";
import { Link } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CollapsibleTable from "../../../components/TableDropDown/CollapsibleTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  resetDataCreate,
  resetDataUpdate,
  resetsingleOrder,
} from "../../../store/orderSlice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import RefreshIcon from "@mui/icons-material/Refresh";
function MainOrder() {
  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllOrders({ lang: i18n.language }));
    dispatch(resetsingleOrder(false));
    dispatch(resetDataCreate(false));
    dispatch(resetDataUpdate(false));
  }, []);

  let {
    data: orders,
    loading,
    errorAll,
  } = useSelector((state) => state.orders);
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Orders")}</h3>
          <button
            className="reload"
            style={{
              backgroundColor: "#054900",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => dispatch(getAllOrders({ lang: i18n.language }))}
          >
            <RefreshIcon />
            {t("Reload page")}
          </button>
        </div>
        {errorAll ? (
          <ErrorCompo />
        ) : loading ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <div className="table table-order">
            <CollapsibleTable orders={orders} />
          </div>
        )}
      </div>
    </div>
  );
}

export default MainOrder;
