import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import Navbar from "../../components/Navbar/Navbar";
import { getAllusers } from "../../store/userslice";
const Notofaction = () => {
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState({
    body: "",
    title: "",
  });
  const [user_ids, setIds] = useState({
    user_ids: [],
  });

  const handleToggleSelectAll = () => {
    // const allUserIds = colourOptions.map((option) => option);
    // setIds({ user_ids: allUserIds });
    if (user_ids.user_ids.length === colourOptions.length) {
      setIds({ user_ids: [] }); // Deselect all users
    } else {
      const allUserIds = colourOptions.map((option) => option);
      setIds({ user_ids: allUserIds }); // Select all users
    }
  };

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllusers({ lang: i18n.language }));
  }, []);
  const [user, setUser] = useState([]);

  let { data: users, errorAll } = useSelector((state) => state.users);
  useEffect(() => {
    if (typeof users === "object") {
      setUser(users);
    }
  }, [users]);
  const colourOptions =
    Array.isArray(user) && user?.map((t) => ({ value: t.id, label: t.name }));
  const [t, i18n] = useTranslation();
  const sendData = (e) => {
    e.preventDefault();
    const postData = {
      user_ids: user_ids.user_ids.map((d) => d.value),

      data: {
        body: data.body,
        title: data.title,
      },
    };
    setLoading(true);
    fetch("https://control.farmy.peaklink.site/public/api/send-notification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setIds({ user_ids: [] });
        setdata({ body: "", title: "" });
        setLoading(false);
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        toast.success(data.message);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="col-xl-10 col-lg-12 mt-1">
        <Navbar />
        <div className="two-box">
          <div className="main-header">
            <h3>{t("Notifaction")}</h3>
          </div>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{t("send")}</CardTitle>
            </CardHeader>

            <CardBody>
              <form onSubmit={sendData}>
                <Row>
                  <Col sm="12" className="mb-1">
                    <Label className="form-label" for="nameVertical">
                      {t("title")}
                    </Label>
                    <Input
                      onChange={(e) => {
                        setdata({ ...data, title: e.target.value });
                      }}
                      value={data.title}
                      type="text"
                      name="Title"
                      id="Title"
                      placeholder={t("title")}
                    />
                  </Col>
                  <Col sm="12" className="mb-1">
                    <Label className="form-label" for="EmailVertical">
                      {t("body")}
                    </Label>
                    <Input
                      onChange={(e) => {
                        setdata({ ...data, body: e.target.value });
                      }}
                      value={data.body}
                      type="text"
                      name="Body"
                      id="Body"
                      placeholder={t("body")}
                    />
                  </Col>
                  <Col sm="12" className="mb-1">
                    <Label className="form-label" for="EmailVertical">
                      {t("users")}
                    </Label>
                    <Select
                      value={user_ids.user_ids}
                      defaultValue={"sdsdsdsdsd"}
                      isMulti
                      name="colors"
                      options={colourOptions}
                      onChange={(e) => {
                        setIds({
                          ...user_ids,
                          user_ids: e.map((d) => d),
                        });
                      }}
                      className=" mt-2 basic-multi-select"
                      classNamePrefix="select"
                    />
                    <button
                      style={{
                        background: "#4F8640",
                        color: "white",
                        padding: "10px",
                        borderRadius: "80px",
                        marginTop: "20px",
                        textAlign: "center",
                        border: "none",
                      }}
                      onClick={handleToggleSelectAll}
                      type="button"
                    >
                      {" "}
                      {user_ids.user_ids.length === colourOptions.length
                        ? t("Deselect")
                        : t("Select")}
                    </button>
                  </Col>

                  <Col sm="12" className="mb-1">
                    {loading ? (
                      <button
                        disabled
                        style={{
                          background: "#4F8640",
                          color: "white",
                          padding: "10px",
                          borderRadius: "80px",
                          marginTop: "20px",
                          textAlign: "center",
                          border: "none",
                        }}
                        type="button"
                      >
                        {" "}
                        {t("loading...")}
                      </button>
                    ) : (
                      <button
                        style={{
                          background: "#4F8640",
                          color: "white",
                          padding: "10px",
                          borderRadius: "80px",
                          marginTop: "20px",
                          textAlign: "center",
                          border: "none",
                        }}
                        type="submit"
                      >
                        {" "}
                        {t("send")}
                      </button>
                    )}
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Notofaction;
