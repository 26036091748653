// routes.js
import { Navigate } from "react-router-dom";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import NotFound from "./pages/NotFoundPage/NotFound";
import AdminHome from "./pages/AdminHome/AdminHome.jsx";
import MainRegions from "./pages/Folder-Regions/Main-Regions/MainRegions.jsx";
import MainSellers from "./pages/Folder-Sellers/MainSellers/MainSellers.jsx";
import MainCategory from "./pages/Folder-Category/MainCategory/MainCategory.jsx";
import MainSubCategory from "./pages/Folder-SubCategory/MainSubCategory/MainSubCategory.jsx";
import MainVariant from "./pages/Folder-Variant/MainVariant/MainVariant.jsx";
import MainProduct from "./pages/Folder-Product/MainProduct/MainProduct.jsx";
import CreateEditProduct from "./pages/Folder-Product/CreateEditProduct/CreateEditProduct.jsx";
import ViewProdcut from "./pages/Folder-Product/ViewProdcut/ViewProdcut.jsx";
import RoleAndPermision from "./pages/Folder-role/RoleAndPermision/RoleAndPermision.jsx";
import RolePermEditAdd from "./pages/Folder-role/RolePermisionEditAdd/RolePermEditAdd.jsx";
import MainUser from "./pages/Folder-Users/MainUser/MainUser.jsx";
import EditCreateUser from "./pages/Folder-Users/EditCreateUser/EditCreateUser.jsx";
import ViewUser from "./pages/Folder-Users/ViewUser/ViewUser.jsx";
import EditCreateCaptin from "./pages/Folder-Captins/EditCreateCaptin/EditCreateCaptin.jsx";
import MainCaptins from "./pages/Folder-Captins/MainCaptins/MainCaptins.jsx";
import FavoUser from "./pages/Folder-Users/FavoUser/FavoUser.jsx";
import ViewCaptin from "./pages/Folder-Captins/ViewCaptin/ViewCaptin.jsx";
import MainOrder from "./pages/Folder-order/MainOrder/MainOrder.jsx";
import MainNotification from "./pages/FolderNotification/MainNotification/MainNotification.jsx";
// import Notifaction from "./pages/Folder-Notifaction/Notofaction.jsx";
import MainState from "./pages/Folder-State/MainState/MainState.jsx";
import MainCommission from "./pages/Folder-Commission/MainCommission/MainCommission.jsx";
import MainDeliveryMethods from "./pages/Folder-DeliveryMethod/MainDeliveryMethods/MainDeliveryMethods.jsx";
import MainDeliveryAttrebute from "./pages/Folder-DeliveryAttrebute/MainDeliveryAttrebute/MainDeliveryAttrebute.jsx";
import MainBanners from "./pages/Folder-Banners/MainBanners/MainBanners.jsx";
import MainGift from "./pages/Folder-Gift/MainGift/MainGift.jsx";
import AddressesUser from "./pages/Folder-Users/AddressesUser/AddressesUser.jsx";
import OrderDetails from "./pages/Folder-order/OrderDetails/OrderDetails.jsx";
import OrderSockit from "./pages/Folder-order/OrderSockit/OrderSockit.jsx";
import MainDeliveryMessage from "./pages/Folder-DeliveryMessage/MainDeliveryMessage/MainDeliveryMessage.jsx";
import Coupons from "./pages/Folder-Coupons/MainDeliveryCoupons/Coupons.jsx"
import MainComplaints from "./pages/Folder-Complaints/MainComplaints/MainComplaints.jsx";
import Notifaction from "./pages/Folder-Notifaction/Notofaction.jsx";
const useRout = (token, sidebarOpen) => {
  const routes = [
    { path: "/", element: token ? <AdminHome /> : <Login /> },
    {
      path: "/login",
      element: token ? <Navigate to="/" replace /> : <Login />,
    },
    { path: "/Register", element: <Register /> },
    // { path: "roles-permision", element: <RoleAndPermision /> },
    // { path: "roles-permision/:name/:id", element: <RolePermEditAdd /> },
    // { path: "roles-permision/:name", element: <RolePermEditAdd /> },
    // { path: "/areas", element: <MainRegions /> },
    // { path: "/state", element: <MainState /> },
    // { path: "/sellers", element: <MainSellers /> },
    // { path: "/notification", element: <MainNotification /> },
    { path: "/order", element: <MainOrder /> },
    { path: "/notifaction", element: <Notifaction /> },
    // { path: "/notifaction", element: <Notifaction /> },
    { path: "/order/details/:id", element: <OrderDetails /> },
    { path: "/order/socket/:id", element: <OrderSockit /> },
    { path: "/users", element: <MainUser /> },
    // { path: "/user/:name/:id", element: <EditCreateUser /> },
    // { path: "/user/:name", element: <EditCreateUser /> },
    // { path: "/user/view/:id", element: <ViewUser /> },
    // { path: "/user/favourite/:id", element: <FavoUser /> },
    // { path: "/user/addresses/:id", element: <AddressesUser /> },
    // { path: "/captains", element: <MainCaptins /> },
    // { path: "/captains/:name/:id", element: <EditCreateCaptin /> },
    // { path: "/captains/:name", element: <EditCreateCaptin /> },
    // { path: "/captains/view/:id", element: <ViewCaptin /> },
    // { path: "/categories", element: <MainCategory /> },
    // { path: "/sub-categories", element: <MainSubCategory /> },
    // { path: "/commission", element: <MainCommission /> },
    // { path: "/delivery-methods", element: <MainDeliveryMethods /> },
    // { path: "/delivery-message", element: <MainDeliveryMessage /> },
    // { path: "/delivery-attributes", element: <MainDeliveryAttrebute /> },
    // { path: "/banners", element: <MainBanners /> },
    // { path: "/gifts", element: <MainGift /> },
    // { path: "/product-variant", element: <MainVariant /> },
    // { path: "/product", element: <MainProduct /> },
    // { path: "/product/:name/:id", element: <CreateEditProduct /> },
    // { path: "/product/:name", element: <CreateEditProduct /> },
    // { path: "/product/view/:id", element: <ViewProdcut /> },
    // { path: "/coupons", element: <Coupons /> },
    // { path: "/complaints", element: <MainComplaints /> },
    { path: "*", element: <NotFound /> },
  ];
  return routes;
};

export default useRout;
