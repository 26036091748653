// Import necessary libraries and components
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/login/Login";
import useRout from "./Routes";
import Sidebar from "./components/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import "./styled/style.scss";
import Register from "./pages/register/Register";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import LoadingScreen from "./pages/LoadingScreen/LoadingScreen.jsx";
import { navigateTo } from "./store/orderSlice.js";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <BrowserRouter>
          <MainApp />
        </BrowserRouter>
      )}
    </div>
  );
}

function MainApp() {
  let { data } = useSelector((state) => state.persistTheme.auth);
  const routes = useRout(data && data.token);
  let { dark } = useSelector((state) => state.persistTheme.themeslice);
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/order");
    console.log("order");
  }, []);

  const currentRoute = window.location.pathname;

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <div className={dark ? "app dark" : "app"}>
        <div className="row ">
          {currentRoute === "/login" ? (
            ""
          ) : currentRoute === "/register" ? (
            ""
          ) : (
            <div className="col-xl-2 col-lg-2">
              <Sidebar />
            </div>
          )}
          <Fragment>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  exact
                  path={route.path}
                  element={
                    data && data.token ? (
                      route.element
                    ) : route.path === "login" ? (
                      <Login />
                    ) : (
                      <Login path={route.path} />
                    )
                  }
                />
              ))}
            </Routes>
          </Fragment>
        </div>
      </div>
    </div>
  );
}

export default App;