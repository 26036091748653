import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./adminhome.scss";

function AdminHome() {
  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="home">Home page</div>
    </div>
  );
}

export default AdminHome;
