import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let ChangeOrderStatus = createAsyncThunk(
  "Statusslice/ChangeOrderStatusee",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.post(
        `${BASE_URL}/orders/update-status/${arg.id}`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
            lang: arg.lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
export let ChangeProductStatus = createAsyncThunk(
  "Statusslice/ChangeProductStatus",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    console.log("arg", arg);
    try {
      let data = await axios.post(
        `${BASE_URL}/change-product-status`,
        arg.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        }
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

let Statusslice = createSlice({
  name: "Statusslice",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorDelete: false,
    errorCaptain: false,
    errorSingle: false,
    errorCreate: false,
    errorAll: false,
    loadingBTNTwo: false,
    dataUpdateStatus: false,
  },
  reducers: {
    resetAllSellerAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleOrder: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdateStatus = action.payload;
    },
  },
  extraReducers: {
    [ChangeOrderStatus.pending]: (state, action) => {
      state.loadingBTNTwo = true;
      state.error = false;
    },
    [ChangeOrderStatus.fulfilled]: (state, action) => {
      state.loadingBTNTwo = false;
      state.dataUpdateStatus = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [ChangeOrderStatus.rejected]: (state, action) => {
      state.loadingBTNTwo = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    [ChangeProductStatus.pending]: (state, action) => {
      state.loadingBTNTwo = true;
      state.error = false;
    },
    [ChangeProductStatus.fulfilled]: (state, action) => {
      state.loadingBTNTwo = false;
      state.dataUpdateStatus = action.payload;
      notifysuccess(action.payload.message);
      // state.data = action.payload;
    },
    [ChangeProductStatus.rejected]: (state, action) => {
      state.loadingBTNTwo = false;
      state.error = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default Statusslice.reducer;
export let {
  resetAllSellerAction,
  resetsingleOrder,
  resetDataCreate,
  resetDataUpdate,
} = Statusslice.actions;
