import React, { useEffect } from "react";
import "./OrderDetails.scss";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../../components/Navbar/Navbar";
import img1 from "../../../assits/IMG_profile.JPG";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { getSingleOrder } from "../../../store/orderSlice";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";

function OrderDetails() {
  let { name, id } = useParams();
  const [t, i18n] = useTranslation();
  let navigate = useNavigate();
  let goBackFn = () => {
    navigate(-1);
  };

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleOrder({ id: id, lang: i18n.language }));
  }, [id]);

  let {
    data: orders,
    loadingBTN,
    error,
    dataCreate,
    dataUpdate,
    loadingSingle,
    errorSingle,
    singleData,
  } = useSelector((state) => state.orders);

  console.log("singleData==>", singleData);

  return (
    <div className="col-xl-10 col-lg-12 mt-1">
      <Navbar />
      <div className="two-box">
        <div className="main-header">
          <h3>{t("Order Details")}</h3>

          <div className="btn-list">
            <button onClick={(e) => goBackFn()} className="btn">
              <KeyboardBackspaceIcon /> {t("Back")}
            </button>
          </div>
        </div>
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <div className="row order-detalis">
            <div className="col-lg-12">
              <hr />
            </div>
            <div className="col-md-8">
              <div className="box-man">
                <div className="box-card">
                  <img src={img1} alt="..." title="test" />
                  <div className="content-card">
                    <p>
                      <PersonIcon />
                      <span className="span-card">
                        {singleData?.user?.username}
                      </span>
                    </p>
                    <p>
                      <LocalPhoneIcon />
                      <span className="span-card phone">
                        {" "}
                        {singleData?.user?.phone}
                      </span>
                    </p>
                  </div>
                  <h6>{t("User")}</h6>
                </div>

                <div className="box-card">
                  <img src={img1} alt="..." title="test" />
                  {singleData && singleData.driver ? (
                    <div className="content-card">
                      <p>
                        <PersonIcon />
                        <span className="span-card">
                          {singleData.driver.username}
                        </span>
                      </p>
                      <p>
                        <LocalPhoneIcon />
                        <span className="span-card phone">
                          {" "}
                          {singleData.driver.phone}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div className="content-card">
                      <span style={{ color: "crimson" }}>
                        {t("No driver has been assigned yet")}
                      </span>
                    </div>
                  )}
                  <h6>{t("Captain")}</h6>
                </div>
              </div>
              <div className="box-location row">
                <h6>{t("Location")}</h6>
                <div className="col-md-4">
                  <div className="box-content">
                    <span className="title">{t("Address")} : </span>
                    <span className="title-2">
                      {" "}
                      {singleData && singleData.user_address.address}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-content ">
                    <span className="title">{t("Area")} : </span>
                    <span className="title-2">
                      {" "}
                      {singleData && singleData.user_address.area}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-content ">
                    <span className="title">{t("Building")} : </span>
                    <span className="title-2">
                      {" "}
                      {singleData && singleData.user_address.building}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-content ">
                    <span className="title">{t("Building Number")} : </span>
                    <span className="title-2">
                      {singleData && singleData.user_address.building_number}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-content  ">
                    <span className="title">{t("Floor")} : </span>
                    <span className="title-2">
                      {singleData && singleData.user_address.floor}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box-content ">
                    <span className="title">{t("Street")} : </span>
                    <span className="title-2">
                      {singleData && singleData.user_address.street}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="box-order-right">
                <h6>{t("Bill")}</h6>
                <div className="box-content">
                  <span className="title">{t("Total Net")} : </span>
                  <span className="title-2">
                    {" "}
                    {singleData && singleData.invoice.subtotal}
                  </span>
                </div>
                <div className="box-content">
                  <span className="title">{t("Discount")} : </span>
                  <span className="title-2"> 0</span>
                </div>
                <div className="box-content">
                  <span className="title">{t("Delivery Charge")} : </span>
                  <span className="title-2">
                    {" "}
                    {singleData && singleData.invoice.delivery_price}
                  </span>
                </div>
                <div className="box-content">
                  <span className="title">{t("Tax")} : </span>
                  <span className="title-2">
                    {" "}
                    {singleData && singleData.invoice.tax}
                  </span>
                </div>
                <div className="box-content">
                  <span className="title total">{t("Total Amount")} : </span>
                  <span className="title-2 total">
                    {" "}
                    {singleData && singleData.invoice.total}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-12 table">
              <table className="table black-color">
                <thead>
                  <tr style={{ backgroundColor: "rgb(18 51 7)" }}>
                    <th style={{ width: "15%" }}>
                      <b style={{ color: "white" }}>No </b>
                    </th>
                    <th>
                      <b style={{ color: "white" }}>{t("Product")}</b>
                    </th>
                    <th>
                      <b style={{ color: "white" }}>{t("Quantity")}</b>
                    </th>
                    <th>
                      <b style={{ color: "white" }}>{t("Discount")}</b>
                    </th>
                    <th>
                      <b style={{ color: "white" }}>{t("Tax")}</b>
                    </th>
                    <th>
                      <b style={{ color: "white" }}>{t("Price")}</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {singleData &&
                    singleData.order_details.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="black-color">{index + 1}</div>
                        </td>
                        <td>
                          <div className="black-color">{item.product.name}</div>
                        </td>
                        <td>{item.quantity}</td>
                        <td>{item.product.discount}</td>
                        <td>{item.product.tax ? item.product.tax : 0}</td>
                        <td>
                          <div className="black-color">{item.price} sp</div>
                        </td>
                      </tr>
                    ))}

                  <tr>
                    <td colSpan={6} style={{ height: "45px" }}></td>
                  </tr>
                  <tr>
                    <td>
                      <div className="black-color">Net Amount</div>
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color">1000 {t("sp")}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="black-color">Tax Amount</div>
                    </td>
                    <td></td>
                    <td />
                    <td />
                    <td>
                      <div className="black-color">20 {t("sp")}</div>
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td>
                      <div className="black-color">{t("Total Amount")}</div>
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color" />
                    </td>
                    <td>
                      <div className="black-color">1020 {t("sp")}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderDetails;
