import React, { useEffect, useState } from "react";
import "./PopUpAssignOrder.scss";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ErrorCompo from "../../../components/ErrorCompo/ErrorCompo";
import CircularIndeterminate from "../../../components/CircularIndeterminate/CircularIndeterminate";
import {
  getAllCaptain,
  getAllCaptainOrders,
  resetsingleDataCaptain,
} from "../../../store/captainSlice";
import { assignOrder } from "../../../store/orderSlice";

function PopUpAssignOrder({ handleClose, type, data, title }) {
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  let [inp, setInp] = useState("");

  console.log("data==>", data);
  const [t, i18n] = useTranslation();
  let dispatch = useDispatch();

  let getValue = (e) => {
    setInp((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    dispatch(getAllCaptainOrders({ lang: i18n.language }));
    dispatch(resetsingleDataCaptain(""));
  }, []);

  let {
    data: captains,
    loading: loadingSingle,
    error: errorSingle,
  } = useSelector((state) => state.captains);

  console.log("captains==>", captains);

  const handleAssignCaptain = () => {
    const orderAssigned = {
      driver_id: parseFloat(inp.driver_id),
      order_id: data 
    };
    dispatch(assignOrder(orderAssigned ));
    console.log("orderAssigned", orderAssigned);
  };

  // let onsubmitfn = (e) => {
  //   e.preventDefault();
  //   if (type === "add") {
  //     console.log("addd");
  //     dispatch(createGift({ data: inp, lang: i18n.language }));
  //   }
  //   if (type === "edit") {
  //     console.log("ediit");
  //     let newdata = {
  //       ...inp,
  //       _method: "PUT",
  //     };
  //     dispatch(
  //       updateGift({
  //         id: singleData.id,
  //         data: newdata,
  //         lang: i18n.language,
  //       })
  //     );
  //   }
  // };
  let loadingBTN = false;

  return (
    <div style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <div className="head">
          <div className="title-head">
            <span>{t("Assign Catptain")}</span>
            <CloseIcon className="icon" onClick={handleClose} />
          </div>
        </div>
      </Typography>

      <Typography
        id="transition-modal-description"
        className="cate-modal"
        sx={{ mt: 2 }}
      >
        {errorSingle ? (
          <ErrorCompo />
        ) : loadingSingle ? (
          <div className="loading">
            <CircularIndeterminate />
          </div>
        ) : (
          <form class="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="text-dark" htmlFor="fname">
                  {t("Captains")}: <sup className="redstar">*</sup>
                </label>
                <select
                  style={{
                    BorderColor: "gray",
                    padding: "3px 30px",
                    color: "gray",
                    marginBottom: "8px",
                  }}
                  className="form-select"
                  onChange={(e) => getValue(e)}
                  name="driver_id"
                  defaultValue={"select Value Please"}
                >
                  <option disabled={true} value={"select Value Please"}>
                    {"select Value Please"}
                  </option>
                  {captains &&
                    captains.map((e) => {
                      return (
                        <option value={e.id}>
                          {e.first_name + " " + e.last_name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="btn-lest mt-3">
              {loadingBTN ? (
                <button type="text" disabled className="btn btn-primary-rgba">
                  <CheckCircleIcon color="#fff" /> {t("Loading")}...
                </button>
              ) : (
                <button
                  onClick={() => handleAssignCaptain()}
                  type="button"
                  className="btn btn-primary-rgba"
                >
                  <CheckCircleIcon color="#fff" />
                  {t("Assign")}
                </button>
              )}

              <span onClick={handleClose} className="btn btn-danger-rgba">
                <DoNotDisturbAltIcon color="#fff" /> {t("Cancel")}
              </span>
            </div>
          </form>
        )}
      </Typography>
    </div>
  );
}

export default PopUpAssignOrder;
