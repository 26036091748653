import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { notifyError, notifysuccess } from "../Notification";
import { BASE_URL } from "../apiConfig";

export let assignOrder = createAsyncThunk(
  "orders/assignOrder",
  async (arg, ThunkAPI) => {
    console.log("arg", arg);
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token; // Access the token from the Redux store
    try {
      let res = await axios.post(`${BASE_URL}/asign-order-to-driver`, arg, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });
      return res;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const getAllOrders = createAsyncThunk(
  "orders/getAllDeliveryMEthod",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const data = await axios.get(`${BASE_URL}/all-orders`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          lang: arg.lang,
        },
      });
      return data.data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const ArciveOrder = createAsyncThunk(
  "orders/ArciveOrder",
  async (arg, ThunkAPI) => {
    const { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;
    try {
      const idList = Array.isArray(arg.id) ? arg.id : [arg.id]; // Ensure id is an array
      const promises = idList.map((id) =>
        axios.post(`${BASE_URL}/orders/${id}`, arg.data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            lang: arg.lang,
          },
        })
      );

      // Wait for all deletion requests to complete
      const responses = await Promise.all(promises); // Wait for all deletion requests to complete

      return { idList: idList, responses: responses }; // Return the list of deleted IDs
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export let getSingleOrder = createAsyncThunk(
  "orders/getSingleOrder",
  async (arg, ThunkAPI) => {
    let { rejectWithValue, getState } = ThunkAPI;
    const token = getState().persistTheme.auth.data.token;

    try {
      let data = await axios.get(`${BASE_URL}/orders/${arg.id}`, {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
          lang: arg.lang,
        },
      });

      return data.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// export let ChangeOrderStatus = createAsyncThunk(
//   "orders/ChangeOrderStatus",
//   async (arg, ThunkAPI) => {
//     let { rejectWithValue, getState } = ThunkAPI;
//     const token = getState().persistTheme.auth.data.token;

//     try {
//       let data = await axios.post(
//         `${BASE_URL}/orders/update-status/${arg.id}`,
//         arg.data,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//             Authorization: `Bearer ${token}`, // Include the token in the Authorization header.
//             lang: arg.lang,
//           },
//         }
//       );
//       return data.data;
//     } catch (error) {
//       return rejectWithValue(
//         error.response ? error.response.data : error.message
//       );
//     }
//   }
// );

export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async (orderId, thunkAPI) => {
    try {
      const response = await axios.delete(`${BASE_URL}/orders/${orderId}`);
      return response; // Return any relevant data after successful deletion
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

let ordersslice = createSlice({
  name: "orders",
  initialState: {
    loadingcountry: false,
    loadingroles: false,
    data: false,
    dataUpdate: false,
    dataCreate: false,
    error: false,
    showslider: false,
    roles: false,
    states: false,
    City: false,
    loading: false,
    loadingSingle: false,
    singleData: false,
    loadingBTN: false,
    loadingStates: false,
    loadingCity: false,
    state: false,
    errorStates: false,
    errorDelete: false,
    errorCaptain: false,
    errorSingle: false,
    errorCreate: false,
    errorAll: false,
  },
  reducers: {
    navigateTo(state, action) {
      state.currentRoute = action.payload;
    },
    resetAllSellerAction: (state, action) => {
      state.data = action.payload;
    },
    resetsingleOrder: (state, action) => {
      state.singleData = action.payload;
    },
    resetDataCreate: (state, action) => {
      state.dataCreate = action.payload;
    },
    resetDataUpdate: (state, action) => {
      state.dataUpdate = action.payload;
    },
  },
  extraReducers: {
    //deleteOrder

    [deleteOrder.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [deleteOrder.fulfilled]: (state, action) => {
      // state.data = state.data.filter(
      //   (e) => !action.payload.idList.includes(e.id)
      // );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.data.message);
    },
    [deleteOrder.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(action.payload.data.message);
    },

    //createDeliveryMethod

    [assignOrder.pending]: (state, action) => {
      state.loadingBTN = true;
      state.errorCreate = false;
    },
    [assignOrder.fulfilled]: (state, action) => {
      state.errorCreate = false;
      state.dataCreate = action.payload;
      notifysuccess(action.payload.data.message);
      state.loadingBTN = false;
    },
    [assignOrder.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorCreate = action.payload;
      notifyError(action.payload.data.message);
    },

    //ChangeOrderStatus

    // [ChangeOrderStatus.pending]: (state, action) => {
    //   state.loadingBTN = true;
    //   state.error = false;
    // },
    // [ChangeOrderStatus.fulfilled]: (state, action) => {
    //   state.loadingBTN = false;
    //   state.dataUpdate = action.payload;
    //   notifysuccess(action.payload.message);
    //   // state.data = action.payload;
    // },
    // [ChangeOrderStatus.rejected]: (state, action) => {
    //   state.loadingBTN = false;
    //   state.error = action.payload;
    //   notifyError(
    //     action.payload?.message && action.payload.message
    //       ? action.payload.message
    //       : action.payload
    //   );
    // },

    //getSingleOrder

    [getSingleOrder.pending]: (state, action) => {
      state.loadingSingle = true;
      state.errorSingle = false;
    },
    [getSingleOrder.fulfilled]: (state, action) => {
      state.singleData = action.payload.data;
      state.errorSingle = false;
      state.loadingSingle = false;
    },
    [getSingleOrder.rejected]: (state, action) => {
      state.loadingSingle = false;
      state.errorSingle = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //ArciveOrder

    [ArciveOrder.pending]: (state, action) => {
      state.loadingBTN = true;
      state.error = false;
    },
    [ArciveOrder.fulfilled]: (state, action) => {
      state.data = state.data.filter(
        (e) => !action.payload.idList.includes(e.id)
      );
      state.loadingBTN = false;
      state.error = false;
      notifysuccess(action.payload.responses[0].data.message);
    },
    [ArciveOrder.rejected]: (state, action) => {
      state.loadingBTN = false;
      state.errorDelete = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },

    //getAllOrders

    [getAllOrders.pending]: (state, action) => {
      state.loading = true;
      state.errorAll = false;
      state.data = false;
    },
    [getAllOrders.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.errorAll = false;
      state.loading = false;
    },
    [getAllOrders.rejected]: (state, action) => {
      state.loading = false;
      state.errorAll = action.payload;
      notifyError(
        action.payload?.message && action.payload.message
          ? action.payload.message
          : action.payload
      );
    },
  },
});

export default ordersslice.reducer;
export let {
  resetAllSellerAction,
  navigateTo,
  resetsingleOrder,
  resetDataCreate,
  resetDataUpdate,
} = ordersslice.actions;
